import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import {
  lightBackground,
  lightButton,
  white,
} from "../../Assets/Theme/ThemeColors";

export default function BasicTable({ tableData }) {
  console.log("tableData", tableData);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Message</TableCell>
            <TableCell align="left">Message By</TableCell>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Time</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="center">Attachment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.message}
              </TableCell>
              <TableCell align="left">{row.CommentBy.user_name}</TableCell>
              <TableCell align="left">{row.createdAt.split("T")[0]}</TableCell>
              <TableCell align="left">
                {row.date.split("T")[1].split(".")[0]}
              </TableCell>

              <TableCell align="left">
                {row.order_status ? row.order_status : ""}
              </TableCell>
              <TableCell align="center">
                {row.attachment.length > 0 ? (
                  <Button
                    variant="contained"
                    style={{
                      background: `${lightButton}`,
                      color: `${lightBackground}`,
                    }}
                    margin="normal"
                    href={row.attachment ? row.attachment : ""}
                    // color="transparent"
                    target="_blank"
                    download
                  >
                    <DownloadIcon />
                  Attachment
                  </Button>
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
